module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/logo/logo.svg","name":"Zenless Zone Zero Music","short_name":"ZZZ Music","start_url":"/?utm_source=pwa","background_color":"#18181b","theme_color":"#18181b","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3e14addc8385a67fb1a699c9c2d1399a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":[],"workboxConfig":{"globPatterns":[],"runtimeCaching":[{"urlPattern":{},"handler":"NetworkOnly"}]}},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
