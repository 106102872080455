export const characters = [
  { name: "alexandrina", image: "/characters/alexandrina.png" },
  { name: "anby", image: "/characters/anby.png" },
  { name: "anton", image: "/characters/anton.png" },
  { name: "ben", image: "/characters/ben.png" },
  { name: "billy", image: "/characters/billy.png" },
  { name: "burnice", image: "/characters/burnice.png" },
  { name: "caesar", image: "/characters/caesar.png" },
  { name: "corin", image: "/characters/corin.png" },
  { name: "ellen", image: "/characters/ellen.png" },
  { name: "grace", image: "/characters/grace.png" },
  { name: "harumasa", image: "/characters/harumasa.png" },
  { name: "koleda", image: "/characters/koleda.png" },
  { name: "lighter", image: "/characters/lighter.png" },
  { name: "lucy", image: "/characters/lucy.png" },
  { name: "lycaon", image: "/characters/lycaon.png" },
  { name: "miyabi", image: "/characters/miyabi.png" },
  { name: "nekomiya", image: "/characters/nekomiya.png" },
  { name: "nicole", image: "/characters/nicole.png" },
  { name: "piper", image: "/characters/piper.png" },
  { name: "qingyi", image: "/characters/qingyi.png" },
  { name: "seth", image: "/characters/seth.png" },
  { name: "soukaku", image: "/characters/soukaku.png" },
  { name: "yanagi", image: "/characters/yanagi.png" },
  { name: "zhu-yuan", image: "/characters/zhu-yuan.png" },
  { name: "astra-yao", image: "/characters/astra-yao.png" },
];
